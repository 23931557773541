@import "../font.scss";
.certificateCard {
  h3 {
    color: #3c3e42 !important;
    font-family: "Lato", sans-serif !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important; /* 28.6px */
    margin-bottom: 8px !important;
  }
  h3.certTitle {
    color: #72B1C8 !important; 


  }
  p {
    color: #555 !important;
    font-family: "Lato", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130% !important; /* 23.4px */
    margin-bottom: 20px !important;
  }
  .courseArea {
    h5 {
      font-family: "Lato", sans-serif !important;
      font-size: 18px !important;
      font-weight: 700 !important;
      line-height: 23px !important;
      letter-spacing: 0em;
      color: #555 !important;
      text-align: left !important;
      margin-bottom: 6px !important;
    }
    p {
      margin-bottom: 6px !important;
    }
    p:last-child {
      margin-bottom: 0px !important;
    }
  }
  h6 {
    color: #3c3e42 !important;
    font-family: "Lato", sans-serif !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important; /* 22.1px */
    margin-bottom: 8px !important;
    display: inline-flex;
    align-items: center;
    span.level {
      color: #555 !important;
      font-family: "Lato", sans-serif !important;
      font-size: 17px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 130% !important; /* 22.1px */
      display: inline-block;
      margin-left: 8px;
    }
    .icon {
      margin-left: 10px !important;
      display: inline-flex;
      align-items: center;
    }
  }
}
.certificateArea {
  min-width: calc(100% - 500px) !important;
  max-width: calc(100% - 500px) !important;
  min-height: 387px;
}
.certificateBg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}
.completeTitle {
  font-family: "EB Garamond", serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  padding-top: 14px !important;
  padding-left: 30px !important;
  color: #fff !important;
  max-width: 174px;
  span {
    font-family: "EB Garamond", serif !important;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 35px !important;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
  }
}
.certificateContent {
  padding-top: 72px;
  padding-left: 26px;
  max-width: 339px;
  text-align: left;
  p {
    
    color: #555 !important;
    font-family: "Lato-Medium" !important;
    font-size: 10.037px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 110% !important; /* 11.041px */
    margin-bottom: 0px !important;
    max-width: 283px !important;
  }
  p.extraContent {
    line-height: 130% !important;
}

  h4 {
    color: #3c3e42 !important;
    font-family: "Lato-Medium" !important;
    font-size: 24.535px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 110% !important; /* 26.988px */
    margin-bottom: 6px !important;
  }
}
.certificateType {
  border-radius: 4px !important;
  background: #fffaea !important;
  & > div {
    display: inline-flex;
  }
  h4 {
    color: #555 !important;
    font-family: "Lato", sans-serif !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important; /* 22.1px */
    margin-bottom: 0px !important;
    max-height: 20px;
    display: inline-flex;
    i,
    img {
      margin-right: 8px !important;
      font-size: 19px !important;
      line-height: 19px !important;
    }
  }
  a {
    color: #476f7c !important;
    font-family: "Lato", sans-serif !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 130% !important; /* 22.1px */
    text-decoration: none;
  }
}
.certificateIssue {
  padding-left: 26px;
  padding-top: 13px;
  padding-bottom: 40px;
  p {
    color: #555 !important;
    font-family: "Lato-Medium" !important;
    font-size: 8.922px !important;
    font-weight: 500 !important;
    line-height: 130% !important; /* 11.598px */
    margin-bottom: 4px !important;
    strong {
      padding-right: 3px;
      font-family: "Lato", sans-serif !important;
      font-size: 8.922px !important;
      font-weight: 700 !important;
      line-height: 130% !important;
      margin-bottom: 2px !important;
    }
  }
}
.byName {
  text-align: center;
  max-width: 146px;
  margin-left: 30px;
  position: relative;
  &:after {
    background: linear-gradient(
        90deg,
        #e3a455 -4.16%,
        #f6dba6 33.25%,
        #ffebc3 74.65%,
        #f0be79 95.91%,
        #8f653b 109.11%,
        #673d22 116.59%,
        #ba7f3b 134.27%,
        #eebc70 148.42%,
        #3f1f0f 180.63%
      ),
      #d9d9d9;
    content: "";
    position: absolute;
    left: 0px;
    top: -5px;
    width: 100%;
    height: 1.67px;
  }
  h5 {
    padding-top: 3px;
    padding-bottom: 3px;
    color: #555 !important;
    font-family: "Lato-Medium" !important;
    font-size: 8.922px !important;
    font-weight: 500 !important;
    line-height: 130% !important; /* 11.598px */
    text-transform: uppercase !important;
  }
  p {
    color: #555 !important;
    font-family: "Lato-Medium" !important;
    font-size: 6.691px !important;
    font-weight: 500 !important;
    line-height: 130% !important; /* 11.598px */
    text-transform: uppercase;
    opacity: 0.5;
  }
}
.signature {
  display: flex;
  padding-left: 26px;
}

.byName {
  margin-left: 0px;
  min-width: 85px;
  position: relative;
  margin-right: 15px;
}
.topBorder{
  border-top: 1px solid #DCDCDC;;
}