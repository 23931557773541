@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&family=Inter:wght@700&family=Lato:wght@400;700&display=swap");
@font-face {
  font-family: "Lato-Medium";
  src: url("Lato-Medium.eot");
  src: url("Lato-Medium.eot?#iefix") format("embedded-opentype"),
    url("Lato-Medium.woff2") format("woff2"),
    url("Lato-Medium.woff") format("woff"),
    url("Lato-Medium.svg#Lato-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
