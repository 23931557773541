@import "./font.scss";
.container {
  max-width: 1054px !important;
  margin: 0px auto !important;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
$f: 0;
@while $f < 1000 {
  //Classes for font size
  .text_#{$f} {
    font-size: 1px * $f !important;
  }
  //Classes for line height
  .line_height_#{$f} {
    line-height: 1px * $f !important;
  }

  //Classes for letter spacing
  .letter_spacing_#{$f} {
    letter-spacing: 1px * $f !important;
  }
  //Classes for margin
  .m#{$f} {
    margin: 1px * $f !important;
  }
  //Classes for margin
  .mB#{$f} {
    margin-bottom: 1px * $f !important;
  }
  .mL#{$f} {
    margin-left: 1px * $f !important;
  }
  .maxW#{$f} {
    max-width: 1px * $f !important;
  }
  //Classes for padding
  .p#{$f} {
    padding: 1px * $f !important;
  }
  //Classes for padding top
  .pT#{$f} {
    padding-top: 1px * $f !important;
  }
  //Classes for padding left
  .pL#{$f} {
    padding-left: 1px * $f !important;
  }
  //Classes for padding left
  .pR#{$f} {
    padding-right: 1px * $f !important;
  }
  //Classes for padding bottom
  .pB#{$f} {
    padding-bottom: 1px * $f !important;
  }
  .border#{$f} {
    border: 1px * $f !important;
  }
  $f: $f + 1;
}
.h100 {
  height: 100%;
}
.textLeft {
  text-align: left;
}
.w80 {
  width: 80px;
}

.imgFluid {
  max-width: 100%;
}
.positionRelative {
  position: relative;
}
.borderGrey {
  border: 1px solid #dcdcdc !important;
}
.dFlex {
  display: flex;
}
.alignItemCenter {
  align-items: center;
}
.buttonSecondary {
  border: 1px solid rgba(60, 62, 66, 0.25) !important;
  border-radius: 4px !important;
  padding: 10px 16px !important;
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #555555 !important;
  text-transform: capitalize !important;
  text-decoration: none;

  i {
    color: #3c3e42 !important;
    font-size: 12px !important;
    line-height: 12px !important;
    margin-right: 8px !important;
  }
}
.certificateTitle {
  font-family: "Lato", sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 31px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #3c3e42;
}

.headerLeft {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  i {
    margin-right: 12px;
  }
}
.linkedInImg {
  img{
    width: 125px;

  }

}